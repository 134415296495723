#login-screen-container {
    height: 100vh;
    margin: 0;
    max-width: 100vw;
    padding: 0;
    width: 100vw;
}

#sign-in-text {
    color: rgb(38, 90, 167);
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
}

#sign-in-button-container {
    display: flex;
    align-content: center;
    justify-content: center;
}

#ezytrack-logo-img {
    max-width: 100%;
}

#login-flair-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    object-fit: contain;
}

#right-container {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0;
    padding: 0;
}

#form-container {
    background-color: white;
    padding: 16px 6px 16px 6px;
    margin: 8%;
    border-radius: 8px;
}

@media screen and (max-width: 767px) {
    #right-container {
        background-image: url("/public/asset/img/login-background-960-compressed.jpg");
    }
}

#left-container {
    height: 100vh;
    margin: 0;
    padding: 0;
    width: 50%;
    background-image: url("/public/asset/img/login-background-960-compressed.jpg");
    background-repeat: repeat;
    background-size: cover;
    position: relative;
}

#sign-in-button {
    background: #259f47;
    border: none;
    min-width: 60%;
    min-height: 32px;
    color: white;
    font-family: Montserrat;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
}

#logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-text-box {
    max-width: 483px !important;
}