.table-row {
    cursor: pointer;
    padding: 8px 0px 8px 0px;
}

.table-row-divider {
    margin: 4px 0px 4px 0px;
}

.table-cell {
    display: flex;
    justify-content: left;
    align-content: center;
}

.table-row-collapsed {
    display: none !important;
}
