#navigation-bar-main-container {
    background-color: #265aa7;
    color: white;
    height: var(--ezheader-row-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
}

#navigation-bar-sub-container {
    background-color: #b0bed4;
    color: black;
    min-height: 32px;
}

.link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.link:hover {
    background-color: #b0bed4;
}

.link-current {
    background-color: #b0bed4;
    color: black;
}

#header-container {
    padding: 0
}

.caret-off::before {
    display: none !important;
}

.caret-off::after {
    display: none !important;
}

#nav-bar-profile-toggle {
    box-shadow: none;
}

#profile-container {

}
