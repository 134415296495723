#loading-wheel-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

#loading-text,
#no-timesheets-found-header,
#no-timesheets-found-text {
    font-family: Montserrat;
    font-weight: 600;
    color: rgb(38, 90, 167);
}

#loading-text,
#no-timesheets-found-header {
    font-size: 26px;
}

#no-timesheets-found-text {
    font-size: 14px;
}

@media screen and (max-width: 767px) {

    #loading-text,
    #no-timesheets-found-header,
    #no-timesheets-found-text {
        text-align: center;
    }
}



#daily-activity-header-container {
    height: var(--dactscn-row-height);
}