:root {
    --dactscn-row-height: 64px;
    --ezheader-row-height: 76px;
    --map-playback-control-height: 64px;
}

.row {
    margin: 0px !important;
}

h1 {
    font-family: Montserrat;
    font-size: 26px !important;
    font-weight: bold !important;
    color: rgb(38, 90, 167) !important;
}

p {
    font-family: Montserrat;
    font-size: 16px;
}

@media screen and (max-width: 767px) {
    h1 {
        text-align: center;
    }

    p {
        text-align: center;
    }
}

.input-text-box {
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(84, 92, 119);
}

/* Font Awesome Pro */
@font-face {
    font-family: "Font Awesome";
    src: url('./Assets/Fonts/FontAwesome5Pro/Regular/FontAwesome5Pro-Regular-400.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Font Awesome";
    src: url('./Assets/Fonts/FontAwesome5Pro/Light/FontAwesome5Pro-Light-300.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Font Awesome";
    src: url('./Assets/Fonts/FontAwesome5Pro/Solid/FontAwesome5Pro-Solid-900.otf') format("opentype");
    font-weight: bold;
    font-style: normal;
}

/* Montserrat */
@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/Black/Montserrat-Black-900.ttf') format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/BlackItalic/Montserrat-BlackItalic-900.ttf') format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/Bold/Montserrat-Bold-700.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/BoldItalic/Montserrat-BoldItalic-700.ttf') format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/ExtraBold/Montserrat-ExtraBold-800.ttf') format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/ExtraBoldItalic/Montserrat-ExtraBoldItalic-800.ttf') format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/ExtraLight/Montserrat-ExtraLight-275.ttf') format("truetype");
    font-weight: 275;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/ExtraLightItalic/Montserrat-ExtraLightItalic-275.ttf') format("truetype");
    font-weight: 275;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/Italic/Montserrat-Italic-400.ttf') format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/Light/Montserrat-Light-300.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/LightItalic/Montserrat-LightItalic-300.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/Medium/Montserrat-Medium-500.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/MediumItalic/Montserrat-MediumItalic-500.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/Regular/Montserrat-Regular-400.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/SemiBold/Montserrat-SemiBold-600.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/SemiBoldItalic/Montserrat-SemiBoldItalic-600.ttf') format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/Thin/Montserrat-Thin-250.ttf') format("truetype");
    font-weight: 250;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./Assets/Fonts/Montserrat/ThinItalic/Montserrat-ThinItalic-250.ttf') format("truetype");
    font-weight: 250;
    font-style: italic;
}