#play-button {
    font-family: "Font Awesome", sans-serif;
    font-weight: bold;
    color: blue;
    cursor: pointer;
}

#playback-range {
    width: 100%;
}

#playback-range-container {
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}


input[type=range] {
    accent-color: #285bae;
    color: white;
    border-radius: 4px;
    border: 1px solid black;
}

#playback-control-container-top {
    margin-top: 12px !important;
}